import { FileKeys } from '@features/file-management-modal/utils/constants/fileKeys';
import { IFIleAfterUploadData } from '@features/file-management-modal/utils/models/IFIleAfterUploadData';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface IFileManagement {
  file: {
    [key in FileKeys]?: IFIleAfterUploadData | {};
  };
}

const initialState: IFileManagement = {
  file: {}
};

export const fileManagementSlice = createSlice({
  name: 'file-management',
  initialState,
  reducers: {
    setSelectedFile: (state, action: PayloadAction<{ key?: FileKeys; value?: IFIleAfterUploadData | {} }>) => {
      state.file[action.payload.key!] = action.payload.value;
    },
    clear: state => {
      state.file = {};
    }
  }
});

export const { setSelectedFile, clear } = fileManagementSlice.actions;
export default fileManagementSlice.reducer;
