import { createSlice, current, PayloadAction } from '@reduxjs/toolkit';

import { helper } from '../../utils/helper/helper';
import { CrmFieldsType } from '../../utils/models/CrmFieldsType';
import {
  BudgetCondition,
  DeadLineAfterStatus,
  EmployeeCondition,
  ErrorList,
  ExecAfterStatus,
  ExecStatus,
  FieldsCondition,
  LogicFields,
  LogicTag,
  OrderTriggerCondition,
  TagCondition,
  TriggerBody,
  TriggerConditionType,
  TYPE_TRIGGER
} from '../../utils/models/Trigger';

interface TriggerState {
  visible: boolean;
  order: number;
  id?: number;
  pipeStatusId: number;
  assignById: number | null;
  useAll: boolean;
  note: string;
  taskTypeId?: number;
  orderTriggerCondition: OrderTriggerCondition[];
  execAfterStatus?: ExecAfterStatus;
  execAfter?: number;
  execStatus?: ExecStatus;
  employee?: EmployeeCondition;
  tag?: TagCondition;
  balance?: BudgetCondition;
  fields?: FieldsCondition[];
  deadLineAfterStatus?: DeadLineAfterStatus;
  deadLineAfter?: number;
  typeTrigger: TYPE_TRIGGER;
  errorList: ErrorList[];
  toPipeStatusId: number;
  changeEmployees: number[];
}

const initialState: TriggerState = {
  visible: false,
  assignById: null,
  useAll: false,
  note: '',
  pipeStatusId: -1,
  orderTriggerCondition: [],
  order: -1,
  deadLineAfter: 0,
  execStatus: ExecStatus.WHEN_CREATE_THIS_STAGE,
  execAfterStatus: ExecAfterStatus.NOW,
  errorList: [],
  typeTrigger: TYPE_TRIGGER.TASK_TRIGGER,
  toPipeStatusId: -1,
  changeEmployees: []
};

export const triggerSlice = createSlice({
  name: 'trigger',
  initialState,
  reducers: {
    setVisible: (
      state,
      action: PayloadAction<{
        visible: boolean;
        id: number | undefined;
        pipeStatusId: number;
        typeTrigger: TYPE_TRIGGER;
        order?: number;
      }>
    ) => {
      state.visible = action.payload.visible;
      state.id = action.payload.id;
      state.pipeStatusId = action.payload.pipeStatusId;
      state.typeTrigger = action.payload.typeTrigger;
      if (action.payload.order) state.order = action.payload.order;
    },
    setTrigger: (state, action: PayloadAction<TriggerBody>) => {
      let orderTriggerCondition: OrderTriggerCondition[] = [];

      const { tag } = action.payload;

      if (tag) {
        orderTriggerCondition.push({
          order: tag.order,
          type: TriggerConditionType.TAG
        });
      }

      const { balance } = action.payload;

      if (balance) {
        const newOrder: OrderTriggerCondition[] = [];
        let isFindPlace = false;

        if (orderTriggerCondition.length === 0) {
          newOrder.push({
            order: balance.order,
            type: TriggerConditionType.BUDGET
          });
        } else {
          orderTriggerCondition.forEach((item, index) => {
            if (item.order < balance.order && index === orderTriggerCondition.length - 1) {
              newOrder.push(item);
              newOrder.push({
                order: balance.order,
                type: TriggerConditionType.BUDGET
              });
              isFindPlace = true;
            } else if (item.order < balance.order || isFindPlace) {
              newOrder.push(item);
            } else if (item.order > balance.order) {
              newOrder.push({
                order: balance.order,
                type: TriggerConditionType.BUDGET
              });
              isFindPlace = true;
              newOrder.push(item);
            }
          });
        }
        orderTriggerCondition = newOrder;
      }

      const { employee } = action.payload;

      if (employee) {
        const newOrder: OrderTriggerCondition[] = [];
        let isFindPlace = false;

        if (orderTriggerCondition.length === 0) {
          newOrder.push({
            order: employee.order,
            type: TriggerConditionType.EMPLOYEE
          });
        } else {
          orderTriggerCondition.forEach((item, index) => {
            if (item.order < employee.order && index === orderTriggerCondition.length - 1) {
              newOrder.push(item);
              newOrder.push({
                order: employee.order,
                type: TriggerConditionType.EMPLOYEE
              });
              isFindPlace = true;
            } else if (item.order < employee.order || isFindPlace) {
              newOrder.push(item);
            } else if (item.order > employee.order) {
              newOrder.push({
                order: employee.order,
                type: TriggerConditionType.EMPLOYEE
              });
              isFindPlace = true;
              newOrder.push(item);
            }
          });
        }
        orderTriggerCondition = newOrder;
      }

      const { fields } = action.payload;

      if (fields && fields.length > 0) {
        fields.forEach((field, index) => {
          let isFindPlace = false;
          const newOrderInside: OrderTriggerCondition[] = [];

          if (orderTriggerCondition.length === 0 && index === 0) {
            newOrderInside.push({
              order: field.order,
              type: TriggerConditionType.FIELD,
              fieldId: field.fieldId
            });
          } else {
            orderTriggerCondition.forEach((item, i) => {
              if (item.order < field.order && i === orderTriggerCondition.length - 1) {
                newOrderInside.push(item);
                newOrderInside.push({
                  order: field.order,
                  type: TriggerConditionType.FIELD,
                  fieldId: field.fieldId
                });
                isFindPlace = true;
              } else if (item.order < field.order || isFindPlace) {
                newOrderInside.push(item);
              } else if (item.order > field.order) {
                newOrderInside.push({
                  order: field.order,
                  type: TriggerConditionType.FIELD,
                  fieldId: field.fieldId
                });
                isFindPlace = true;
                newOrderInside.push(item);
              }
            });
          }
          orderTriggerCondition = newOrderInside;
        });
      }

      state.id = action.payload.id;
      state.pipeStatusId = action.payload.pipeStatusId;
      state.order = action.payload.order;
      state.fields = fields;
      state.orderTriggerCondition = orderTriggerCondition;
      state.deadLineAfterStatus = action.payload.deadLineAfterStatus;
      state.deadLineAfter = action.payload.deadLineAfter;
      state.execStatus = action.payload.execStatus;
      state.execAfter = action.payload.execAfter;
      state.execAfterStatus = action.payload.execAfterStatus;
      state.note = action.payload.text || '';
      state.useAll = false;
      state.employee = employee;
      state.assignById = action.payload.assignById;
      state.taskTypeId = action.payload.taskTypeId;
      state.toPipeStatusId = action.payload.toPipeStatusId || -1;
      state.changeEmployees = action.payload.employees || [];
      state.balance = {
        order: balance?.order || 0,
        low: balance?.low || 0,
        high: balance?.high || 0,
        lowString: balance?.low?.toLocaleString('ru') || '',
        highString: balance?.high?.toLocaleString('ru') || ''
      };
      state.tag = tag;
    },
    setTagCondition: (state, action: PayloadAction<{ add: boolean }>) => {
      const orderTriggerCondition = [...state.orderTriggerCondition];

      if (action.payload.add) {
        let order = 0;

        if (orderTriggerCondition.length > 0) {
          order = orderTriggerCondition[orderTriggerCondition.length - 1]?.order;
        }
        orderTriggerCondition.push({
          order: order + 1,
          type: TriggerConditionType.TAG
        });
        state.tag = {
          logic: LogicTag.AND,
          tags: [],
          order: order + 1
        };
        state.orderTriggerCondition = orderTriggerCondition;
      } else {
        const newOrders: OrderTriggerCondition[] = [];
        let number = 1;

        orderTriggerCondition.forEach(order => {
          if (order.type !== TriggerConditionType.TAG) {
            newOrders.push({
              ...order,
              order: number
            });
            // eslint-disable-next-line no-plusplus
            number++;
          }
        });
        state.orderTriggerCondition = newOrders;
        state.tag = undefined;
      }
    },
    setTypeTag: (state, action: PayloadAction<LogicTag>) => {
      const { tag } = state;

      if (tag) {
        state.tag = {
          tags: tag.tags,
          order: tag.order,
          logic: action.payload
        };
      }
    },
    setTag: (state, action: PayloadAction<number>) => {
      const { tag } = state;

      if (tag) {
        if (tag.tags.length > 0)
          tag.tags.push({
            tagId: action.payload
          });
        else
          tag.tags = [
            {
              tagId: action.payload
            }
          ];
      }
      state.tag = tag;
    },
    removeTag: (state, action: PayloadAction<number>) => {
      const { tag } = state;

      if (tag) {
        tag.tags = tag.tags.filter(item => item.tagId !== action.payload);
      }
      state.tag = tag;
    },
    setAssignById: (state, action: PayloadAction<number | null>) => {
      state.assignById = action.payload;
    },
    setUseAll: (state, action: PayloadAction<boolean>) => {
      state.useAll = action.payload;
    },
    setNote: (state, action: PayloadAction<string>) => {
      state.note = action.payload;
    },
    setTaskType: (state, action: PayloadAction<number>) => {
      state.taskTypeId = action.payload;
    },
    setBudgetCondition: (state, action: PayloadAction<{ add: boolean }>) => {
      const orderTriggerCondition = [...state.orderTriggerCondition];

      if (action.payload.add) {
        let order = 0;

        if (orderTriggerCondition.length > 0) {
          order = orderTriggerCondition[orderTriggerCondition.length - 1]?.order;
        }
        orderTriggerCondition.push({
          order: order + 1,
          type: TriggerConditionType.BUDGET
        });
        state.balance = {
          low: null,
          highString: '',
          lowString: '',
          high: null,
          order: order + 1
        };
        state.orderTriggerCondition = orderTriggerCondition;
      } else {
        const newOrders: OrderTriggerCondition[] = [];
        let number = 1;

        orderTriggerCondition.forEach(order => {
          if (order.type !== TriggerConditionType.BUDGET) {
            newOrders.push({
              ...order,
              order: number
            });
            // eslint-disable-next-line no-plusplus
            number++;
          }
        });
        state.balance = undefined;
        state.orderTriggerCondition = newOrders;
      }
    },
    setBudgetLow: (state, action: PayloadAction<string>) => {
      const { balance } = state;

      if (balance) {
        balance.low = helper.parseFloatStringToNumber(action.payload);
        balance.lowString = action.payload;
      }
      state.balance = balance;
    },
    setBudgetHigh: (state, action: PayloadAction<string>) => {
      const { balance } = state;

      if (balance) {
        balance.high = helper.parseFloatStringToNumber(action.payload);
        balance.highString = action.payload;
      }
      state.balance = balance;
    },
    setEmployeeCondition: (state, action: PayloadAction<{ add: boolean }>) => {
      const orderTriggerCondition = [...state.orderTriggerCondition];

      if (action.payload.add) {
        let order = 0;

        if (orderTriggerCondition.length > 0) {
          order = orderTriggerCondition[orderTriggerCondition.length - 1]?.order;
        }
        orderTriggerCondition.push({
          order: order + 1,
          type: TriggerConditionType.EMPLOYEE
        });
        state.employee = {
          order: order + 1,
          employees: []
        };
        state.orderTriggerCondition = orderTriggerCondition;
      } else {
        const newOrders: OrderTriggerCondition[] = [];
        let number = 1;

        orderTriggerCondition.forEach(order => {
          if (order.type !== TriggerConditionType.EMPLOYEE) {
            newOrders.push({
              ...order,
              order: number
            });
            // eslint-disable-next-line no-plusplus
            number++;
          }
        });
        state.orderTriggerCondition = newOrders;
        state.employee = undefined;
      }
    },
    setEmployee: (state, action: PayloadAction<number>) => {
      const { employee } = state;

      if (employee) {
        if (employee.employees.length > 0)
          employee.employees.push({
            employeeId: action.payload
          });
        else
          employee.employees = [
            {
              employeeId: action.payload
            }
          ];
      }
      state.employee = employee;
    },
    setChangeEmployee: (state, action: PayloadAction<number>) => {
      const { changeEmployees } = state;

      changeEmployees.push(action.payload);
      state.changeEmployees = changeEmployees;
    },
    removeChangeEmployee: (state, action: PayloadAction<number>) => {
      let employee = state.changeEmployees;

      employee = employee.filter(item => item !== action.payload);
      state.changeEmployees = employee || [];
    },
    removeEmployee: (state, action: PayloadAction<number>) => {
      const { employee } = state;

      if (employee) {
        employee.employees = employee.employees.filter(item => item.employeeId !== action.payload);
      }
      state.employee = employee;
    },
    setFieldCondition: (
      state,
      action: PayloadAction<{
        add: boolean;
        fieldId: number;
        type: CrmFieldsType;
      }>
    ) => {
      const { orderTriggerCondition } = state;

      if (action.payload.add) {
        let order = 0;
        const fields = state.fields || [];

        if (orderTriggerCondition.length > 0) {
          order = orderTriggerCondition[orderTriggerCondition.length - 1]?.order;
        }
        orderTriggerCondition.push({
          order: order + 1,
          type: TriggerConditionType.FIELD,
          fieldId: action.payload.fieldId
        });
        fields.push({
          order: order + 1,
          fieldId: action.payload.fieldId,
          logic: action.payload.type === CrmFieldsType.TEXT ? LogicFields.MATCHES : undefined,
          values: []
        });
        state.orderTriggerCondition = orderTriggerCondition;
        state.fields = fields;
      } else {
        const fields = state.fields || [];
        const newOrders: OrderTriggerCondition[] = [];
        let number = 1;

        orderTriggerCondition.forEach(order => {
          const currentOrder = current(order);

          if (currentOrder.type !== TriggerConditionType.FIELD || currentOrder.fieldId !== action.payload.fieldId) {
            newOrders.push({
              ...currentOrder,
              order: number
            });
            // eslint-disable-next-line no-plusplus
            number++;
          }
        });
        state.orderTriggerCondition = newOrders;
        state.fields = fields.filter(item => item.fieldId !== action.payload.fieldId);
      }
    },
    setFieldValueInput: (
      state,
      action: PayloadAction<{
        value: string;
        id: number;
      }>
    ) => {
      const { fields } = state;
      const newFields: FieldsCondition[] = [];

      fields?.forEach(field => {
        if (field.fieldId === action.payload.id) {
          if (action.payload.value) {
            newFields.push({
              ...field,
              values: [
                {
                  value: action.payload.value
                }
              ]
            });
          } else {
            newFields.push({
              ...field,
              values: []
            });
          }
        } else {
          newFields.push(field);
        }
      });
      state.fields = newFields;
    },
    setFieldInputLogic: (
      state,
      action: PayloadAction<{
        logic: LogicFields;
        id: number;
      }>
    ) => {
      const { fields } = state;
      const newFields: FieldsCondition[] = [];

      fields?.forEach(field => {
        if (field.fieldId === action.payload.id) {
          newFields.push({
            ...field,
            logic: action.payload.logic
          });
        } else {
          newFields.push(field);
        }
      });
      state.fields = newFields;
    },
    setFieldValueSelect: (
      state,
      action: PayloadAction<{
        value: string;
        id: number;
      }>
    ) => {
      const { fields } = state;
      const newFields: FieldsCondition[] = [];

      fields?.forEach(field => {
        if (field.fieldId === action.payload.id) {
          const values = field.values || [];

          values.push({
            value: action.payload.value
          });
          newFields.push({
            ...field,
            values
          });
        } else {
          newFields.push(field);
        }
      });
      state.fields = newFields;
    },
    removeFieldValueSelect: (
      state,
      action: PayloadAction<{
        id: number;
        value: string;
      }>
    ) => {
      const { fields } = state;
      const newFields: FieldsCondition[] = [];

      fields?.forEach(field => {
        if (field.fieldId === action.payload.id) {
          const values = field.values.filter(val => val.value !== action.payload.value);

          newFields.push({
            ...field,
            values: values || []
          });
        } else {
          newFields.push(field);
        }
      });
      state.fields = newFields;
    },
    setDeadLineAfterStatus: (state, action: PayloadAction<DeadLineAfterStatus>) => {
      state.deadLineAfterStatus = action.payload;
      state.deadLineAfter = undefined;
    },
    setDeadLineAfter: (state, action: PayloadAction<number>) => {
      state.deadLineAfterStatus = undefined;
      state.deadLineAfter = action.payload;
    },
    setExecStatus: (state, action: PayloadAction<ExecStatus>) => {
      state.execStatus = action.payload;
      state.execAfterStatus = ExecAfterStatus.NOW;
      state.execAfter = undefined;
    },
    setExecAfterStatus: (state, action: PayloadAction<ExecAfterStatus | undefined>) => {
      state.execAfterStatus = action.payload;
      state.execAfter = undefined;
    },
    setExecAfter: (state, action: PayloadAction<number>) => {
      state.execAfter = action.payload;
    },
    setPipeStatusId: (state, action: PayloadAction<number>) => {
      state.toPipeStatusId = action.payload;
    },
    setErrorList: (state, action: PayloadAction<ErrorList[]>) => {
      state.errorList = action.payload;
    },
    clearAll: state => {
      state.visible = false;
      state.order = -1;
      state.id = undefined;
      state.pipeStatusId = -1;
      state.assignById = null;
      state.useAll = false;
      state.note = '';
      state.taskTypeId = undefined;
      state.orderTriggerCondition = [];
      state.execAfter = undefined;
      state.execAfterStatus = ExecAfterStatus.NOW;
      state.execStatus = ExecStatus.WHEN_CREATE_THIS_STAGE;
      state.employee = undefined;
      state.tag = undefined;
      state.balance = undefined;
      state.fields = undefined;
      state.deadLineAfter = 0;
      state.deadLineAfterStatus = undefined;
      state.errorList = [];
      state.typeTrigger = TYPE_TRIGGER.PIPE_STATUS_TRIGGER;
      state.toPipeStatusId = -1;
      state.changeEmployees = [];
    }
  }
});

export const {
  setVisible,
  setTypeTag,
  setTag,
  removeTag,
  setAssignById,
  setUseAll,
  setNote,
  setTaskType,
  setTagCondition,
  setBudgetCondition,
  setBudgetLow,
  setBudgetHigh,
  setEmployeeCondition,
  setEmployee,
  removeEmployee,
  setFieldCondition,
  setFieldInputLogic,
  setFieldValueInput,
  removeFieldValueSelect,
  setFieldValueSelect,
  setDeadLineAfterStatus,
  setDeadLineAfter,
  setExecAfter,
  setExecAfterStatus,
  setExecStatus,
  setTrigger,
  clearAll,
  setErrorList,
  setPipeStatusId,
  setChangeEmployee,
  removeChangeEmployee
} = triggerSlice.actions;
export default triggerSlice.reducer;
