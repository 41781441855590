import { createSlice, current, PayloadAction } from '@reduxjs/toolkit';
import { DropResult } from 'react-beautiful-dnd';

import { FormaModels } from '../../pages/crm/components/voronkaMessengerSetting/voronkaWebForm/utils/models/FormaModels';
import { Fields, InitialStateModel } from '../../pages/crm/components/voronkaMessengerSetting/voronkaWebForm/utils/models/InitialStateModel';

const initialState: InitialStateModel = {
  main: {
    name: 'Shablon1',
    borderRadius: 30,
    borderColor: 'rgba(208, 213, 221, 1)',
    fontFamily: 'sans-serif',
    leftWidth: 300,
    fullWidth: 860,
    leftBackgroundColor: '#fff',
    leftBackgroundImage: '',
    rightBackgroundColor: '#fff',
    rightBackgroundImage: '',
    boxShadow: false,
    height: 696,
    pipeStatusId: null,
    tagsId: null,
    redirectUrl: '',
    redirectTime: 0,
    textSubmission: '',
    formId: null,
    openShablon: false,
    pageBackgroundColor: '',
    pageBackgroundImage: '',
    checked: false
  },
  leftHeader: {
    header: {
      fontWeight: 'BOLD',
      fontStyle: '',
      textDecoration: '',
      textAlign: 'left',
      color: 'rgba(70, 66, 85, 1)',
      content: 'Header',
      fontSize: 24
    },
    paragraph: {
      fontWeight: '',
      fontStyle: '',
      textDecoration: '',
      textAlign: 'left',
      color: 'rgba(70, 66, 85, 1)',
      content: 'Paragraph',
      fontSize: 14
    }
  },
  fields: {
    3: {
      name: 'board1',
      activeTask: 'none',
      items: [
        {
          label: 'Phone',
          name: 'Phone',
          companyLeadFieldId: 80,
          placeHolder: '',
          required: true,
          requiredMessage: '',
          step: 0,
          type: 'PHONE'
        }
      ]
    },
    4: {
      name: 'board2',
      activeTask: 'none',
      items: []
    }
  },
  fieldsStyle: {
    height: 40,
    borderRadius: 'SQUARE',
    fieldBorderColor: '',
    fieldBackgroundColor: '',
    color: '#00A389',
    fieldBorderType: 'SQUARE',
    fieldSize: 14
  },
  textPosition: {
    labelPosition: 'block'
  },
  submitButton: {
    fontWeight: '',
    fontStyle: '',
    textDecoration: '',
    justifyContent: 'left',
    borderRadius: 'SQUARE',
    color: '#fff',
    backgroundColor: '#3cbd9f',
    value: 'Yuborish',
    width: 0
  },
  leftBgImgBinary: undefined,
  rightBgImgBinary: undefined,
  pageBackImg: undefined
};

export const textEditorSlice = createSlice({
  name: 'text-editor',
  initialState,
  reducers: {
    // main styles
    mainBorderRadius: (state, action: PayloadAction<number>) => {
      state.main.borderRadius = action.payload;
    },
    mainBorderColor: (state, action: PayloadAction<string>) => {
      state.main.borderColor = action.payload;
    },
    mainFontFamily: (state, action: PayloadAction<string>) => {
      state.main.fontFamily = action.payload;
    },
    mainLeftWidth: (state, action: PayloadAction<number>) => {
      state.main.leftWidth = action.payload;
    },
    mainFullWidth: (state, action: PayloadAction<number>) => {
      state.main.fullWidth = action.payload;
    },
    mainLeftBackgroundColor: (state, action: PayloadAction<string>) => {
      state.main.leftBackgroundColor = action.payload;
    },
    mainRightBackgroundColor: (state, action: PayloadAction<string>) => {
      state.main.rightBackgroundColor = action.payload;
    },
    mainRightBackgroundImage: (state, action: PayloadAction<string>) => {
      state.main.rightBackgroundImage = action.payload;
    },
    mainBoxShadow: (state, action: PayloadAction<boolean>) => {
      state.main.boxShadow = action.payload;
    },
    changeFormName: (state, action: PayloadAction<string>) => {
      state.main.name = action.payload;
    },
    mainHeight: (state, action: PayloadAction<number>) => {
      state.main.height = action.payload;
    },
    changePipeStatusId: (state, action: PayloadAction<number>) => {
      state.main.pipeStatusId = action.payload;
    },
    changeTagsId: (state, action: PayloadAction<number[] | null>) => {
      state.main.tagsId = action.payload;
    },
    changeFontWeight: (state, action: PayloadAction<{ fontWeight: string; type: string }>) => {
      const { fontWeight } = state.leftHeader[action.payload.type as keyof typeof state.leftHeader];

      if (fontWeight === action.payload.fontWeight) {
        state.leftHeader[action.payload.type as keyof typeof state.leftHeader].fontWeight = '';
      } else {
        state.leftHeader[action.payload.type as keyof typeof state.leftHeader].fontWeight = action.payload.fontWeight;
      }
    },
    changeFontStyle: (state, action: PayloadAction<{ fontStyle: string; type: string }>) => {
      const { fontStyle } = state.leftHeader[action.payload.type as keyof typeof state.leftHeader];

      if (fontStyle === action.payload.fontStyle) {
        state.leftHeader[action.payload.type as keyof typeof state.leftHeader].fontStyle = '';
      } else {
        state.leftHeader[action.payload.type as keyof typeof state.leftHeader].fontStyle = action.payload.fontStyle;
      }
    },
    changeTextDecoration: (state, action: PayloadAction<{ textDecoration: string; type: string }>) => {
      const { textDecoration } = state.leftHeader[action.payload.type as keyof typeof state.leftHeader];

      if (textDecoration === action.payload.textDecoration) {
        state.leftHeader[action.payload.type as keyof typeof state.leftHeader].textDecoration = '';
      } else {
        state.leftHeader[action.payload.type as keyof typeof state.leftHeader].textDecoration = action.payload.textDecoration;
      }
    },
    changeTextAlign: (state, action: PayloadAction<{ textAlign: string; type: string }>) => {
      const { textAlign } = state.leftHeader[action.payload.type as keyof typeof state.leftHeader];

      if (textAlign === action.payload.textAlign) {
        state.leftHeader[action.payload.type as keyof typeof state.leftHeader].textAlign = '';
      } else {
        state.leftHeader[action.payload.type as keyof typeof state.leftHeader].textAlign = action.payload.textAlign;
      }
    },
    changeTextColor: (state, action: PayloadAction<{ color: string; type: string }>) => {
      state.leftHeader[action.payload.type as keyof typeof state.leftHeader].color = action.payload.color;
    },
    changeTextContent: (state, action: PayloadAction<{ content: string; type: string }>) => {
      state.leftHeader[action.payload.type as keyof typeof state.leftHeader].content = action.payload.content;
    },
    changeTextSize: (state, action: PayloadAction<{ fontSize: number; type: string }>) => {
      state.leftHeader[action.payload.type as keyof typeof state.leftHeader].fontSize = action.payload.fontSize;
    },
    changeRedirectUrl: (state, action: PayloadAction<string>) => {
      state.main.redirectUrl = action.payload;
    },
    changeRedirectTime: (state, action: PayloadAction<number>) => {
      state.main.redirectTime = action.payload;
    },
    changeTextSubmission: (state, action: PayloadAction<string>) => {
      state.main.textSubmission = action.payload;
    },
    setChangePageBackgroundColor: (state, action: PayloadAction<string>) => {
      state.main.pageBackgroundColor = action.payload;
    },
    setChangeChecked: (state, action: PayloadAction<boolean>) => {
      state.main.checked = action.payload;
    },

    fieldDragDrop: (state, action: PayloadAction<DropResult>) => {
      const result = action.payload;

      if (!result.destination) return;
      const { source, destination } = result;

      const columns = state.fields;

      if (source.droppableId !== destination.droppableId) {
        const sourceColumn = columns[Number(source?.droppableId) as keyof typeof columns];
        const destColumn = columns[Number(destination.droppableId) as keyof typeof columns];
        const sourceItems = [...sourceColumn.items];
        const destItems = [...destColumn.items];

        const deleted = sourceItems[source.index];
        const [removed] = sourceItems.splice(source.index, deleted?.public ? 0 : 1);

        // destColumn.activeTask =  removed.label
        destItems.splice(destination.index, 0, deleted?.public ? deleted : removed);
        state.fields = {
          ...columns,
          [source.droppableId]: {
            ...sourceColumn,
            items: sourceItems
          },
          [destination.droppableId]: {
            ...destColumn,
            items: destItems.map(item => ({ ...item, label: item.name }))
          }
        };
      } else {
        const column = columns[Number(source.droppableId) as keyof typeof columns];
        const copiedItems = [...column.items];
        const [removed] = copiedItems.splice(source.index, 1);

        copiedItems.splice(destination.index, 0, removed);
        state.fields = {
          ...columns,
          [source.droppableId]: {
            ...column,
            items: copiedItems
          }
        };
      }
    },
    removeField: (state, action: PayloadAction<{ index: number; elem: string }>) => {
      const { index } = action.payload;

      state.fields['3'].items[index].label = state.fields['3'].items[index].name;
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      !state.fields['3']?.items[index].public && state.fields['4'].items.push(state.fields['3']?.items[index]);
      state.fields['3'].items = state.fields['3'].items?.filter((item, i) => i !== index);
    },
    changeFaildLabel: (state, action: PayloadAction<{ label: string; index: number }>) => {
      state.fields[3].items[action.payload.index].label = action.payload.label;
    },
    changeFaildContent: (state, action: PayloadAction<{ index: number; content: string }>) => {
      state.fields[3].items[action.payload.index].placeHolder = action.payload.content;
    },
    changeFieldRequired: (state, action: PayloadAction<{ switch: boolean; index: number }>) => {
      state.fields[3].items[action.payload.index].required = action.payload.switch;
    },
    changeFaildMessage: (state, action: PayloadAction<{ message: string; index: number }>) => {
      state.fields[3].items[action.payload.index].requiredMessage = action.payload.message;
    },
    // fields style actions
    changeFieldBorderRadius: (state, action: PayloadAction<string>) => {
      state.fieldsStyle.fieldBorderType = action.payload;
    },
    changeFieldHeight: (state, action: PayloadAction<number>) => {
      state.fieldsStyle.height = action.payload;
    },
    changeFieldBorderColor: (state, action: PayloadAction<string>) => {
      state.fieldsStyle.fieldBorderColor = action.payload;
    },
    changeFieldBackgroundColor: (state, action: PayloadAction<string>) => {
      state.fieldsStyle.fieldBackgroundColor = action.payload;
    },
    changeFieldColor: (state, action: PayloadAction<string>) => {
      state.fieldsStyle.color = action.payload;
    },

    // text position actions
    textPositionLeft: (state, action: PayloadAction<string>) => {
      state.textPosition.labelPosition = action.payload;
    },

    // button actions
    submitFontWeight: (state, action: PayloadAction<string>) => {
      const { fontWeight } = state.submitButton;

      if (fontWeight === action.payload) {
        state.submitButton.fontWeight = '';
      } else {
        state.submitButton.fontWeight = action.payload;
      }
    },
    submitFontStyle: (state, action: PayloadAction<string>) => {
      const { fontStyle } = state.submitButton;

      if (fontStyle === action.payload) {
        state.submitButton.fontStyle = '';
      } else {
        state.submitButton.fontStyle = action.payload;
      }
    },
    submitTextDecoration: (state, action: PayloadAction<string>) => {
      const { textDecoration } = state.submitButton;

      if (textDecoration === action.payload) {
        state.submitButton.textDecoration = '';
      } else {
        state.submitButton.textDecoration = action.payload;
      }
    },
    submitJustifyContent: (state, action: PayloadAction<string>) => {
      state.submitButton.justifyContent = action.payload;
    },
    submitBorderRadius: (state, action: PayloadAction<string>) => {
      state.submitButton.borderRadius = action.payload;
    },
    submitBackgroundColor: (state, action: PayloadAction<string>) => {
      state.submitButton.backgroundColor = action.payload;
    },
    submitColor: (state, action: PayloadAction<string>) => {
      state.submitButton.color = action.payload;
    },
    submitValue: (state, action: PayloadAction<string>) => {
      state.submitButton.value = action.payload;
    },
    submitBUttonWidth: (state, action: PayloadAction<number>) => {
      state.submitButton.width = action.payload;
    },
    addData: (state, action: PayloadAction<Fields[]>) => {
      state.fields[4].items = action.payload;
    },
    setEditForm: (state, action: PayloadAction<FormaModels>) => {
      const { fields } = current(state);
      const header = action.payload.staticElementFormDto.find(item => item?.type === 'HEADER')!;
      const paragraph = action.payload.staticElementFormDto.find(item => item?.type === 'PARAGRAPH')!;
      const button = action.payload.staticElementFormDto.find(item => item?.type === 'BUTTON')!;

      state.leftHeader = {
        ...state.leftHeader,
        header: {
          ...state.leftHeader.header,
          color: header?.textColor,
          content: header?.text || 'Header',
          fontSize: header?.textSize || 23,
          fontStyle: header?.fontStyle[1],
          fontWeight: header?.fontStyle[0],
          textAlign: header?.alignment || 'LEFT',
          textDecoration: header?.fontStyle[2]
        },
        paragraph: {
          ...state.leftHeader.paragraph,
          color: paragraph?.textColor,
          content: paragraph?.text || 'Paragraph',
          fontSize: paragraph?.textSize || 13,
          fontStyle: paragraph?.fontStyle[1] || '',
          fontWeight: paragraph?.fontStyle[0],
          textAlign: paragraph?.alignment || 'LEFT',
          textDecoration: paragraph?.fontStyle[2] || ''
        }
      };
      state.fieldsStyle = {
        ...state.fieldsStyle,
        borderRadius: String(action.payload.style.borderRadius) || '0',
        color: action.payload.style.fontColor || '#00A389',
        fieldBackgroundColor: action.payload.style.fieldBackgroundColor,
        fieldBorderColor: action.payload.style.fieldBorderColor || '',
        height: action.payload.style.fieldHeight || 40
      };
      state.main = {
        ...state.main,
        borderColor: action.payload.style.borderColor,
        borderRadius: action.payload.style.borderRadius || 40,
        boxShadow: action.payload.style.boxShadow || false,
        fontFamily: action.payload.style.fontFamily,
        fullWidth: action.payload.style.leftWidth + action.payload.style.rightWidth || 860,
        height: action.payload.style.height || 696,
        leftBackgroundColor: action.payload.style.leftBackgroundColor,
        leftBackgroundImage: action.payload.style.leftBackgroundImage,
        leftWidth: action.payload.style.leftWidth || 300,
        name: action.payload.name,
        pipeStatusId: action.payload.pipeStatusId,
        redirectTime: action.payload.redirectTime,
        redirectUrl: action.payload.redirectUrl,
        rightBackgroundColor: action.payload.style.rightBackgroundColor,
        rightBackgroundImage: action.payload.style.rightBackgroundImage,
        tagsId: action.payload.tagIds || null,
        textSubmission: action.payload.textSubmission,
        pageBackgroundImage: action.payload.style.pageBackgroundImage,
        pageBackgroundColor: action.payload.style.pageBackgroundColor
      };
      state.submitButton = {
        ...state.submitButton,
        backgroundColor: button.buttonColor || '',
        borderRadius: button.buttonBorderType || 'SQUARE',
        color: button.textColor,
        fontStyle: button.fontStyle[1],
        fontWeight: button.fontStyle[0],
        justifyContent: button.alignment || 'LEFT',
        textDecoration: button.fontStyle[2],
        value: button.text || 'Yuborish',
        width: button.buttonWidth || 200
      };
      state.textPosition = {
        ...state.textPosition,
        labelPosition: action.payload.style.labelPosition
      };
      state.fields['3'].items = action.payload.elements
        ?.sort((a, b) => a?.step - b?.step)
        .map(item => ({ ...item, id: item?.companyLeadFieldId, name: item?.label }));
      state.fields['4'].items = fields['4'].items.filter(item => !action.payload.elements.some(elem => elem?.companyLeadFieldId === item.id));
    },
    clearForm: state => {
      state.fields = {
        3: initialState.fields[3],
        4: {
          name: 'board2',
          activeTask: 'none',
          items: [...state?.fields[4]?.items, ...state?.fields[3]?.items?.filter(item => item?.label !== 'Phone')]
        }
      };
      state.fieldsStyle = initialState.fieldsStyle;
      state.leftHeader = initialState.leftHeader;
      state.main = initialState.main;
      state.submitButton = initialState.submitButton;
      state.textPosition = initialState.textPosition;
      state.leftBgImgBinary = initialState.leftBgImgBinary;
      state.rightBgImgBinary = initialState.rightBgImgBinary;
      state.pageBackImg = initialState.pageBackImg;
    },
    getFormId: (state, action: PayloadAction<number | null>) => {
      state.main.formId = action.payload;
    },
    setLeftBgImgBinary: (
      state,
      action: PayloadAction<{
        backImage: string;
        type: string;
      }>
    ) => {
      if (action.payload.type === 'rightImgBinary') {
        state.rightBgImgBinary = action.payload.backImage;
      } else if (action.payload.type === 'backImg') {
        state.pageBackImg = action.payload.backImage;
      } else {
        state.leftBgImgBinary = action.payload.backImage;
      }
    },
    setChangeBackColor: (state, action: PayloadAction<{ backColor: string; type: string }>) => {
      if (action.payload.type === 'rightImgBinary') {
        state.main.rightBackgroundColor = action.payload.backColor;
      } else {
        state.main.leftBackgroundColor = action.payload.backColor;
      }
    },
    setOpenShablon: (state, action: PayloadAction<boolean>) => {
      state.main.openShablon = action.payload;
    },
    setFieldsFontSize: (state, action: PayloadAction<number>) => {
      state.fieldsStyle.fieldSize = action.payload;
    }
  }
});

export const {
  changeFontStyle,
  changeFontWeight,
  changeTextDecoration,
  changeTextAlign,
  changeTextColor,
  changeTextSize,
  fieldDragDrop,
  removeField,
  changeFieldBorderRadius,
  changeFieldHeight,
  changeFieldColor,
  changeFieldBorderColor,
  changeFieldBackgroundColor,
  changeFieldRequired,
  textPositionLeft,
  submitFontWeight,
  submitFontStyle,
  submitTextDecoration,
  submitJustifyContent,
  submitBorderRadius,
  submitBackgroundColor,
  submitColor,
  submitValue,
  submitBUttonWidth,
  mainBorderRadius,
  mainBorderColor,
  mainFontFamily,
  mainLeftWidth,
  mainFullWidth,
  mainLeftBackgroundColor,
  mainRightBackgroundColor,
  mainBoxShadow,
  changeFormName,
  changeFaildLabel,
  changeFaildContent,
  changeFaildMessage,
  changeTextContent,
  changeRedirectUrl,
  changeRedirectTime,
  changeTextSubmission,
  addData,
  mainHeight,
  changePipeStatusId,
  changeTagsId,
  setEditForm,
  clearForm,
  getFormId,
  setLeftBgImgBinary,
  setOpenShablon,
  setFieldsFontSize,
  setChangePageBackgroundColor,
  setChangeChecked,
  setChangeBackColor
} = textEditorSlice.actions;
export default textEditorSlice.reducer;
