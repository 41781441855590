import i18n from 'i18next';
import languageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

import { resources } from '../utils/lang/resources';

const DETECTION_OPTIONS = {
  order: ['localStorage', 'navigator'],
  caches: ['localStorage']
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(Backend)
  .use(languageDetector)
  .init({
    resources,
    load: 'languageOnly',
    detection: DETECTION_OPTIONS,
    supportedLngs: ['uz', 'ru', 'en', 'kr', 'tr'],
    fallbackLng: 'uz',
    react: {
      useSuspense: false
    }
  });

export default i18n;
