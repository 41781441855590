import en from './en.json';
import ru from './ru.json';
import tr from './tr.json';
import uz from './uz.json';

export const resources = {
  ru: {
    translation: ru
  },
  uz: {
    translation: uz
  },
  en: {
    translation: en
  },
  tr: {
    translation: tr
  }
};
